import { Badge, BadgeProps, Box, BoxProps } from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'
import React from 'react'

import useSettings from '../hooks/useSettings'
// material

// ----------------------------------------------------------------------
const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 7,
    top: 40,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    ['@media (max-width:360px)']: {
      top: 35,
    },
  },
}))

// eslint-disable-next-line react/display-name
const Logo = React.forwardRef<any, BoxProps>(({ sx }, ref) => {
  const theme = useTheme()
  const { themeMode } = useSettings()
  const PRIMARY_LIGHT = theme.palette.primary.light
  const PRIMARY_MAIN = theme.palette.primary.main

  const PRIMARY_DARK = theme.palette.primary.dark
  return (
    // <StyledBadge badgeContent={'beta'} color="primary">
    <Box
      ref={ref}
      sx={{
        width: 165,
        height: 40,
        cursor: 'pointer',
        ...sx,
        ['@media (max-width:360px)']: {
          width: 130,
        },
      }}
    >
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        height="100%"
        width="100%"
        viewBox="0 0 1000 200"
        preserveAspectRatio="xMidYMid meet"
      >
        <g
          transform="translate(0.000000,188.000000) scale(0.100000,-0.100000)"
          fill={PRIMARY_MAIN}
          stroke="none"
        >
          <path
            d="M654 1539 c-341 -33 -523 -166 -593 -432 -31 -118 -44 -310 -32 -453
19 -221 69 -354 170 -455 107 -106 269 -163 511 -178 141 -9 332 -1 437 18
285 53 435 198 489 476 21 108 24 404 5 505 -44 233 -136 368 -302 445 -154
70 -426 100 -685 74z m291 -423 c36 -23 54 -66 65 -149 12 -95 12 -294 0 -381
-18 -125 -57 -161 -173 -160 -75 0 -108 17 -140 72 -29 49 -44 252 -30 406 14
148 33 195 90 220 47 20 152 16 188 -8z"
          />
          <path
            d="M1792 778 l3 -773 80 3 c240 9 413 127 496 337 22 57 23 73 27 362
l4 302 46 -5 c127 -16 301 -95 416 -189 l53 -43 45 34 c51 37 85 77 119 144
34 67 39 204 9 282 -29 77 -94 147 -177 190 -92 48 -160 63 -278 62 -80 -1
-116 -7 -180 -28 -44 -15 -94 -36 -112 -47 l-32 -20 -10 41 c-6 22 -16 58 -21
80 l-11 40 -239 0 -240 0 2 -772z"
          />
          <path
            d="M3785 1540 c-164 -12 -327 -28 -472 -47 l-122 -16 5 -26 c2 -14 15
-99 28 -188 12 -90 24 -165 27 -167 2 -1 146 1 320 7 343 10 409 5 447 -36 18
-20 42 -84 42 -114 0 -8 -81 -12 -307 -15 -303 -3 -309 -4 -380 -30 -124 -44
-205 -119 -240 -220 -20 -58 -28 -236 -14 -326 6 -41 23 -96 37 -124 72 -142
247 -227 465 -228 172 0 323 61 439 179 l65 66 80 -81 c111 -111 198 -149 378
-161 l87 -6 0 474 c0 261 -5 519 -10 574 -26 278 -142 414 -400 471 -82 18
-319 25 -475 14z m275 -971 l0 -80 -67 -31 c-51 -24 -85 -33 -143 -36 -71 -4
-78 -3 -108 23 -30 25 -32 31 -32 89 0 105 19 115 208 116 l142 0 0 -81z"
          />
          <path
            d="M7220 1543 c-136 -8 -416 -34 -528 -49 l-124 -17 5 -26 c3 -14 15
-102 28 -194 l23 -168 61 6 c33 3 193 7 355 8 269 2 298 0 328 -16 37 -20 62
-68 62 -117 l0 -30 -272 0 c-372 0 -460 -21 -572 -133 -74 -74 -96 -143 -96
-308 0 -190 23 -263 111 -351 96 -96 247 -144 428 -136 173 8 299 64 417 185
l61 63 29 -41 c16 -23 53 -62 84 -87 104 -85 191 -112 395 -119 330 -12 575
54 1070 289 237 113 319 138 444 138 149 0 297 -51 410 -140 l52 -41 -7 78
c-9 116 -41 266 -75 354 -115 299 -384 463 -729 446 -319 -17 -486 -126 -884
-576 -153 -173 -231 -251 -250 -251 -3 0 -6 171 -6 379 0 421 -6 483 -59 595
-100 208 -325 285 -761 259z m208 -975 l-3 -83 -60 -29 c-70 -34 -176 -46
-225 -26 -43 18 -63 61 -57 124 3 38 11 55 31 73 26 22 35 23 172 23 l145 0
-3 -82z"
          />
          <path
            d="M4690 1500 c0 -8 139 -433 372 -1138 107 -326 84 -311 506 -312 251
0 309 3 340 15 44 19 79 50 99 89 8 16 96 275 194 575 99 300 197 599 219 664
l39 117 -323 0 -322 0 -88 -402 c-48 -222 -96 -461 -108 -533 -25 -161 -23
-155 -48 -155 -11 0 -20 1 -20 3 -1 53 -59 355 -129 669 -50 225 -91 410 -91
413 0 3 -144 5 -320 5 -199 0 -320 -4 -320 -10z"
          />
        </g>
      </svg>
    </Box>
    // </StyledBadge>
  )
})

export default Logo
