import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

import { isUserSubscribed } from '../../services/subscriptionPlan/getPlan'

import WalletContext from './WalletContext'

type isUserSubscribed = {
  isUserSubscribedStatus: boolean
}

const initialState: isUserSubscribed = {
  isUserSubscribedStatus: false,
}

export const IsUserSubscribedContext = createContext(initialState)

const IsUserSubscribedProvider: React.FC = ({ children }) => {
  const [isUserSubscribedStatus, setIsUserSubscribedStatus] = useState(false)
  const { address } = useContext(WalletContext)

  useEffect(() => {
    const handleCheckUserSubscibed = async (): Promise<void> => {
      if (address) {
        const state = await isUserSubscribed(address)
        if (process.env.NEXT_PUBLIC_ENV === 'development') {
          setIsUserSubscribedStatus(true)
        } else {
          setIsUserSubscribedStatus(state?.subscribed)
        }
      }
    }
    handleCheckUserSubscibed()
  }, [address])

  return (
    <IsUserSubscribedContext.Provider value={{ isUserSubscribedStatus }}>
      {children}
    </IsUserSubscribedContext.Provider>
  )
}

export default IsUserSubscribedProvider
