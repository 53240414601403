import { styled } from '@mui/material/styles'
import { useRouter } from 'next/router'
import { ReactNode } from 'react'

import useSettings from '../../hooks/useSettings'
import Typography from '../../theme/overrides/Typography'

// next
// material
// components
//
import MainFooter from './MainFooter'
import MainNavbar from './MainNavbar'

const themeModeType = {
  DARK: 'dark',
  LIGHT: 'light',
}
// ----------------------------------------------------------------------
// const HeroImgStyle = styled('div')(({ theme }) => ({
//   width: '100%',
//   backgroundImage: '',
// }))
// "url('/static/gradient bg/orava_gradient_bg_dark.jpg')",
type MainLayoutProps = {
  children: ReactNode
}

export default function MainLayout({ children }: MainLayoutProps) {
  const { pathname } = useRouter()
  const { themeMode } = useSettings()

  const HeroImgStyle = styled('div')(({ theme }) => ({
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage:
      themeMode === themeModeType.DARK
        ? `url('/static/gradient bg/orava_gradient_bg_dark.jpg')`
        : '',
  }))
  return (
    <>
      <HeroImgStyle
      // src={
      //   themeMode === themeModeType.DARK
      //     ? '/static/gradient bg/orava_gradient_bg_dark.jpg'
      //     : '/static/gradient bg/orava_gradient_bg_light.jpg'
      // }
      >
        <MainNavbar />
        <div>{children}</div>
        <MainFooter />
      </HeroImgStyle>
    </>
  )
}
