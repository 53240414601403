// next
import { AppBar, Box, Button, Container, Stack, Toolbar, Typography } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import React, { useCallback, useContext, useEffect } from 'react'

import { LoadingState } from '../../../services/nftGated/pages/types'
import { CustomNav, DefaultNav } from '../../../services/shop/types'
// components
import { MHidden } from '../../components/@material-extend'
import Logo from '../../components/Logo'
import SwitchNetwork from '../../components/SwitchNetwork'
import ThemeMode from '../../components/ThemeMode'
import { IsUserSubscribedContext } from '../../contexts/IsUserSubscribedProvider'
import WalletContext from '../../contexts/WalletContext'
import useMediaQuery from '../../hooks/useMediaQuery'
import useOffSetTop from '../../hooks/useOffSetTop'
import useSettings from '../../hooks/useSettings'
import { PATH_DASHBOARD } from '../../routes/paths'
//
import AccountPopover from '../dashboard/AccountPopover'

import navConfig from './MenuConfig'
import MenuDesktop from './MenuDesktop'
import MenuMobile from './MenuMobile'

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 88

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP,
  },
}))

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
}))

// ----------------------------------------------------------------------

export type MenuItemProps = {
  title: string
  onChange?: boolean
  path: string
  icon?: JSX.Element
  actualPath?: string
  children?: {
    subheader: string
    items: {
      title: string
      path: string
    }[]
  }[]
}

export type MenuProps = {
  isOffset: boolean
  isHome: boolean
  navConfig: MenuItemProps[]
  isShop?: boolean
  address?: string
  customNavBar?: CustomNav[]
  defaultNavBar?: DefaultNav[]
  navpageLoader?: LoadingState
}

interface MainNavbarProps {
  isNavOption?: boolean
}

export default function MainNavbar({ isNavOption }: MainNavbarProps) {
  const theme = useTheme()
  const wallet = useContext(WalletContext)
  const { isUserSubscribedStatus } = useContext(IsUserSubscribedContext)
  const connectWallet = useCallback(async () => {
    if (!wallet.address) {
      const walletSelected = await wallet.onboard?.walletSelect()
      if (!walletSelected) return false
      await wallet.onboard.walletCheck()
    }
  }, [wallet])
  const [width] = useMediaQuery()
  const isOffset = useOffSetTop(10)
  const { pathname, push } = useRouter()
  const isHome = pathname === '/'
  const { themeMode, onChangeMode } = useSettings()
  const [selected, setSelected] = React.useState(false)
  const isMobile = width > 769

  // const changeThemeMode = useCallback(
  //   (event, newValue) => {
  //     onChangeMode(event)
  //     setSelected(!selected)
  //   },
  //   [themeMode, onChangeMode]
  // )
  const changeThemeMode = () => {
    onChangeMode(themeMode === 'light' ? 'dark' : 'light')
    setSelected(!selected)
  }
  useEffect(() => {
    themeMode === 'dark' ? setSelected(true) : setSelected(false)
  }, [themeMode])

  const handleRedirectIsSubscribed = async (): Promise<void> => {
    if (isUserSubscribedStatus) push(PATH_DASHBOARD('').shop.list)
  }

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 },
          }),
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/*<MHidden width="mdUp">*/}
          {/*  <NextLink href="/">*/}
          {/*    <Typography>*/}
          {/*      <LogoSmall />*/}
          {/*    </Typography>*/}
          {/*  </NextLink>*/}
          {/*</MHidden>*/}

          <NextLink href="/">
            <Typography>
              <Logo />
            </Typography>
          </NextLink>
          <Box sx={{ flexGrow: 1 }} />

          <>
            <MHidden width="mdDown">
              <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
            </MHidden>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              sx={{ mr: isMobile ? 3 : 0.5 }}
            >
              <ThemeMode
                sx={{
                  padding: '7px',
                  borderRadius: '50%',
                  backgroundColor: 'none',
                  background: 'none',
                  color: 'text.primary',
                }}
              />
            </Stack>
            {/*<Stack*/}
            {/*  direction="row"*/}
            {/*  justifyContent="center"*/}
            {/*  alignItems="center"*/}
            {/*  spacing={2}*/}
            {/*  sx={{ mr: isMobile ? 3 : 0.5 }}*/}
            {/*>*/}
            {/*  <SwitchNetwork />*/}
            {/*</Stack>*/}
            <Stack direction="row" alignItems="center" spacing={2}>
              {!wallet.address && isMobile && (
                <>
                  <Button
                    variant="contained"
                    className="connectMetamask"
                    onClick={connectWallet}
                    sx={{
                      borderRadius: '25px',
                      whiteSpace: 'nowrap',
                      mx: 0.5,
                    }}
                  >
                    Connect Wallet
                  </Button>
                </>
              )}
              {wallet.address && (
                <>
                  {width > 769 && (
                    // <NextLink href={PATH_DASHBOARD('').shop.list}>
                    <Button
                      variant="contained"
                      className="manageStore"
                      sx={{
                        borderRadius: '25px',
                        whiteSpace: 'nowrap',
                      }}
                      onClick={handleRedirectIsSubscribed}
                    >
                      Marketplace
                    </Button>
                    // </NextLink>
                  )}
                  <AccountPopover />
                </>
              )}
            </Stack>{' '}
          </>
          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  )
}
