import instagramFilled from '@iconify/icons-ant-design/instagram-outline'
import linkedinFill from '@iconify/icons-eva/linkedin-fill'
import twitterFill from '@iconify/icons-eva/twitter-fill'
import { Icon } from '@iconify/react'
// next
// material
import { Box, Container, Divider, Grid, IconButton, Link, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import NextLink from 'next/link'
import React, { useContext } from 'react'

//
import Logo from '../../components/Logo'
import { IsUserSubscribedContext } from '../../contexts/IsUserSubscribedProvider'

// ----------------------------------------------------------------------

const SOCIALS = [
  { name: 'Twitter', icon: twitterFill, href: 'https://www.twitter.com', id: 'Twitter' },
  {
    name: 'Instagram',
    icon: instagramFilled,
    href: 'https://www.instagram.com',
    id: 'Instagram',
  },
  {
    name: 'Linkedin',
    icon: linkedinFill,
    href: 'https://www.linkedin.com/company',
    id: 'Linkedin',
  },
  {
    name: 'Discord',
    icon: 'ph:discord-logo',
    href: 'https://discord.gg',
    id: 'Discord',
  },
  {
    name: 'Telegram',
    icon: 'ph:telegram-logo',
    href: 'https://t.me',
    id: 'Telegram',
  },
]

const LINKS = [
  {
    headline: 'About',
    children: [
      { name: 'About us', href: '/about' },
      { name: 'Terms of Service', href: '/termsofservice' },
      { name: 'Privacy Policy', href: '/privacypolicy' },
      { name: 'FAQ', href: '/faq' },
    ],
  },
  {
    headline: 'Support',
    children: [
      { name: 'Create Marketplace', href: '/shop/create' },
      { name: 'Customize Marketplace', href: '/shop' },
      { name: 'Create NFT', href: '/shop' },
      { name: 'Sell NFT', href: '/shop' },
      // { name: 'Help Center', href: '#' },
    ],
  },
  {
    headline: 'Contact Us',
    children: [
      { name: 'hello@orava.me', href: 'mailto:hello@orava.me' },
      // { name: 'Los Angeles, 359  Hidden Valley Road', href: '#' },
    ],
  },
]

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  // backgroundColor: theme.palette.background.default,
}))

// ----------------------------------------------------------------------

export default function MainFooter() {
  const { isUserSubscribedStatus } = useContext(IsUserSubscribedContext)

  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} md={12}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              {LINKS.map((list) => {
                const { headline, children } = list
                return (
                  <Stack key={headline} spacing={2}>
                    <Typography component="p" variant="overline">
                      {headline}
                    </Typography>
                    {headline === 'Support'
                      ? children.map((link) => (
                          <NextLink
                            key={link.name}
                            href={isUserSubscribedStatus ? link.href : ''}
                            passHref
                          >
                            <Link color="inherit" variant="body2" sx={{ display: 'block' }}>
                              {link.name}
                            </Link>
                          </NextLink>
                        ))
                      : children.map((link) => (
                          <NextLink key={link.name} href={link.href} passHref>
                            <Link color="inherit" variant="body2" sx={{ display: 'block' }}>
                              {link.name}
                            </Link>
                          </NextLink>
                        ))}

                    {/* {headline === 'Contact Us' && (
                      <Typography variant="body2">Los Angeles, 359 Hidden Valley Road</Typography>
                    )} */}
                  </Stack>
                )
              })}
            </Stack>
          </Grid>
        </Grid>

        <Stack
          spacing={1.5}
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          justifyContent={{ xs: 'center', md: 'space-between' }}
          alignItems="center"
          sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
        >
          <Typography
            component="p"
            variant="body2"
            sx={{
              mt: 5,
              pb: 5,
              fontSize: 13,
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            Copyright © 2022 Orava. All rights reserved
          </Typography>
          <Box>
            {SOCIALS.map((social) => {
              return (
                <a href={social.href} key={social.id} target="_blank" rel="noreferrer">
                  <IconButton key={social.name} color="primary" sx={{ p: 1 }}>
                    <Icon icon={social.icon} width={16} height={16} />
                  </IconButton>
                </a>
              )
            })}
          </Box>
        </Stack>
      </Container>
    </RootStyle>
  )
}
