export interface Page {
  page: string
  status: string
}

export enum Status {
  DRAFT,
  PUBLISH,
  UNPUBLISH,
}

export interface InitialPageState {
  pages: Page[]
  error: string | null
  loadingState: LoadingState
}

export enum LoadingState {
  IDEAL,
  LOADING,
  DATA_RECEIVED,
  ERROR,
}

export enum PageActionType {
  SET_PAGES,
  SET_ERROR,
  SET_LOADING,
}

export interface PageAction {
  type: PageActionType
  payload: any
}
