// import { createStyles, makeStyles } from '@mui/material'
import { Avatar } from '@mui/material'
import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import Jdenticon from 'react-jdenticon'

import { getUserProfile } from '../../../services/profile/getUserProfile'
import { UserProfile } from '../../../services/profile/type'
import { MAvatar } from '../../../src/components/@material-extend'

const UserAvatar = ({ str = '' }: { str: string }) => {
  const [profile, setProfile] = useState<UserProfile>(null)
  // const [imageIsLoaded, setImageIsLoaded] = useState(false)
  const fetch = async (address) => {
    const profileRes = await getUserProfile(address)
    if (profileRes?.profile) setProfile(profileRes?.profile)
  }
  useEffect(() => {
    fetch(str)
  }, [str])
  const toBase64 = (str) =>
    typeof window === 'undefined' ? Buffer.from(str).toString('base64') : window.btoa(str)
  return (
    <>
      {profile?.profile ? (
        <Avatar color={'default'}>
          <Image
            src={profile?.profile || '/static/illustration/no-image-icon.png'}
            alt={profile?.userName}
            layout="fill"
            blurDataURL={`/static/placeholder.svg}`}
            placeholder="blur"
            loading="lazy"
          />
          {/*{!imageIsLoaded && (*/}
          {/*  <Skeleton animation="wave" variant="circular" width={40} height={40} />*/}
          {/*)}*/}
        </Avatar>
      ) : (
        <MAvatar>{str && <Jdenticon size="40" value={str} />}</MAvatar>
      )}
    </>
  )
}

export default UserAvatar
