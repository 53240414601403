import { Timestamp } from 'firebase/firestore'

export interface UserProfile {
  userName: string
  profile: string
  cover: string
  status: string
  ownerAddress: string
  isPublic: boolean
  userMetaInformation: UserMetaInformation[]
  createdOn: Timestamp
  updatedOn: Timestamp
  deletedOn: Timestamp
  isDeleted: boolean
}
export interface UserMetaInformation {
  type: UserMetaInformationType
  isVerified: boolean
  data: string
}

export enum UserMetaInformationType {
  NUMBER,
  EMAIL,
  FACEBOOK,
  TWITTER,
  INSTAGRAM,
  OTHERS,
}
