import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { ReactNode } from 'react'

interface DashboardLayoutProps {
  children?: ReactNode
  href: string
  key?: string | number
  passHref?: boolean
}

export default function PreviewNextLink({ children, href, key, passHref }: DashboardLayoutProps) {
  const { pathname } = useRouter()
  const isEnabled = '/shop/[shop]/store-setting/pages/edit' !== pathname
  if (isEnabled)
    return (
      <NextLink href={href} key={key} passHref={passHref}>
        {children}
      </NextLink>
    )
  return <>{children}</>
}
