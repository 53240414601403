import blogFill from '@iconify/icons-eva/book-open-fill'
import homeFill from '@iconify/icons-eva/home-fill'
import profileFill from '@iconify/icons-eva/person-outline'
import searchFill from '@iconify/icons-eva/search-fill'
import { Icon } from '@iconify/react'

// routes
import { PATH_DASHBOARD } from '../../routes/paths'

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
}

const menuConfig = [
  {
    title: 'Home',
    onChange: false,
    path: '/',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
  },
  {
    title: 'Discover',
    onChange: false,
    path: PATH_DASHBOARD('').discover,
    icon: <Icon icon={searchFill} {...ICON_SIZE} />,
  },
  {
    title: 'My Profile',
    onChange: false,
    path: `/profile`,
    actualPath: '/profile/[address]',
    icon: <Icon icon={profileFill} {...ICON_SIZE} />,
  },
  // {
  //   title: 'Theme Setting',
  //   onChange: true,
  //   path: ``,
  //   actualPath: '',
  //   icon: <Icon icon={profileFill} {...ICON_SIZE} />,
  // },
  // {
  //   title: 'Blogs',
  //   path: `/blog`,
  //   icon: <Icon icon={blogFill} {...ICON_SIZE} />,
  // },
  // {
  //   title: 'Products',
  //   path: PATH_DASHBOARD('').none,
  //   icon: <Icon icon={fileFill} {...ICON_SIZE} />,
  //   children: [
  //     {
  //       subheader: 'PRODUCTS',
  //       items: [
  //         { title: 'Buy Tokens', path: PATH_DASHBOARD('').none },
  //         { title: 'Token Bridge', path: PATH_DASHBOARD('').none },
  //         { title: 'NFT Bridge', path: PATH_DASHBOARD('').none },
  //       ],
  //     },
  //   ],
  // },
]

export default menuConfig
