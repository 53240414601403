import { Icon } from '@iconify/react'
import { Avatar, Button, Paper, Tooltip, Box, TooltipProps, ListItemIcon } from '@mui/material'
import Popover from '@mui/material/Popover'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Image from 'next/image'
import React, { useEffect, useState } from 'react'

import { getUserProfile } from '../../../../services/profile/getUserProfile'
import { UserMetaInformationType, UserProfile } from '../../../../services/profile/type'
import { truncateAddress } from '../../../utils/addressTruncate'
import CopyClipboard from '../../CopyClipboard'

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    background: ${({ theme }) => theme.palette.background.paper};
    max-width: 340px;
  }
`
const MediaObjectWrap = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '10px 15px',
  minWidth: '294px',
  position: 'relative',
  overflow: 'visible',

  '&::before': {
    content: '""',
    position: 'absolute',
    left: '25%',
    top: '-13px',
    width: 0,
    height: 0,
    borderLeft: '15px solid transparent',
    borderRight: '15px solid transparent',
    borderBottom: `15px solid ${theme.palette.background.paper}`,
    clear: 'both',
  },
}))
const MediaObject = styled('div')({})
const Number = styled('div')({
  fontsize: '14px',
  lineHeight: '22px',
  color: '#8773FF',
  fontWeight: 600,
})
const TextMuted = styled('div')(({ theme }) => ({
  fontsize: '12px',
  lineHeight: '18px',
  color: '#637381',
}))
const IconCircle = styled('div')(({ theme }) => ({
  width: '28px',
  height: '28px',
  lineHeight: '28px',
  textAlign: 'center',
  borderRadius: '50%',
  backgroundColor: '#fff',
  boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.24), 0px 20px 40px -4px rgba(145, 158, 171, 0.24)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '10px',
  '&:last-child': {
    marginRight: '0px',
  },
}))
const icons = {}
icons[UserMetaInformationType.FACEBOOK] = 'eva:facebook-outline'
icons[UserMetaInformationType.INSTAGRAM] = 'ph:instagram-logo'
icons[UserMetaInformationType.TWITTER] = 'iconoir:twitter'
icons[UserMetaInformationType.OTHERS] = 'mdi:web'

export default function UserTooltip(props: { label: any; address: string }) {
  const { label, address } = props
  const [profile, setProfile] = useState<UserProfile>(null)
  const fetch = async (address) => {
    const profileRes = await getUserProfile(address)
    if (profileRes?.profile) setProfile(profileRes?.profile)
  }
  useEffect(() => {
    fetch(address)
  }, [address])
  return (
    <>
      <StyledTooltip
        leaveTouchDelay={10000}
        enterTouchDelay={5}
        title={
          <Typography>
            <MediaObjectWrap>
              <MediaObject sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar sx={{ width: '38px', height: '38px' }}>
                  <Image
                    alt={profile?.userName || 'User Name'}
                    src={profile?.profile || '/static/placeholder.svg'}
                    layout="fill"
                    loading="lazy"
                    blurDataURL={`/static/placeholder.svg`}
                  />
                </Avatar>
                <Box style={{ paddingLeft: '10px' }}>
                  <Typography
                    sx={{
                      py: 1,
                      color: 'text.primary',
                      fontSize: '14px',
                      fontWeight: 600,
                      paddingBottom: '0px',
                    }}
                  >
                    {profile?.userName || 'Unnamed'}
                  </Typography>
                  <Typography sx={{ color: 'text.primary', mt: 0 }}>
                    <CopyClipboard isAddress value={address} />
                  </Typography>
                </Box>
              </MediaObject>
              {/* <Box>
                <TextMuted> Items </TextMuted>
                <Number>24</Number>
              </Box> */}
            </MediaObjectWrap>
            {profile?.userMetaInformation && (
              <ListItemIcon
                sx={{
                  padding: '10px 15px 25px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {profile?.userMetaInformation?.map((item, i) => {
                  if (!icons[item.type] || item.data === '') return ''
                  return (
                    <a
                      href={item.data || '#'}
                      target="_blank"
                      key={i}
                      rel="noreferrer"
                      style={{ margin: '0 8px', display: 'inline-block' }}
                    >
                      <IconCircle sx={{ position: 'relative', cursor: 'pointer' }}>
                        {
                          <Icon
                            icon={icons[item.type] || 'mdi:web'}
                            height={20}
                            width={20}
                            inline={true}

                            // style={{ color: '#5FB8FE', fontWeight: 300 }}
                          />
                        }

                        <img
                          src="/static/icons/offer.svg"
                          alt="Offer"
                          style={{ position: 'absolute', bottom: 0, right: '-3px' }}
                        />
                      </IconCircle>
                    </a>
                  )
                })}
              </ListItemIcon>
            )}
          </Typography>
        }
      >
        {label}
      </StyledTooltip>
    </>
  )
}
