import settings2Fill from '@iconify/icons-ant-design/setting-outlined'
import shopOutlined from '@iconify/icons-ant-design/shop-outline'
import personFill from '@iconify/icons-eva/person-outline'
import { Icon } from '@iconify/react'
import {
  Avatar,
  Box,
  Button,
  Divider,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material'
// next
// material
import { alpha } from '@mui/material/styles'
import BigNumber from 'bignumber.js'
import NextLink from 'next/link'
import Router from 'next/router'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { getUserProfile } from '../../../services/profile/getUserProfile'
import { UserProfile } from '../../../services/profile/type'
import { getUSD } from '../../../services/rates/conversion'
import { MIconButton } from '../../components/@material-extend'
// components
import CopyClipboard from '../../components/CopyClipboard'
import MenuPopover from '../../components/MenuPopover'
import { IsUserSubscribedContext } from '../../contexts/IsUserSubscribedProvider'
import WalletContext from '../../contexts/WalletContext'
import { PATH_DASHBOARD } from '../../routes/paths'

import UserAvatar from './UserAvatar'

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { address, chainId, onboard, ballance } = useContext(WalletContext)
  const { isUserSubscribedStatus } = useContext(IsUserSubscribedContext)
  const myBalance = new BigNumber(ballance || '0').div(Math.pow(10, 18)).toPrecision(5)
  const [usd, setUSD] = useState<number | string>('...')
  const fetchUSD = useCallback(async () => {
    const res = await getUSD('ETH', new BigNumber(myBalance))
    setUSD(res?.amount)
  }, [ballance])
  useEffect(() => {
    fetchUSD()
  }, [ballance])
  const disconnectWallet = useCallback(async () => {
    await onboard.walletReset()
    Router.push('/')
  }, [onboard])

  const anchorRef = useRef(null)

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const MENU_OPTIONS = [
    // { label: 'Wallet', icon: wallet, linkTo: '#' },
    // {
    //   label: 'Promote',
    //   icon: promote,
    //   linkTo: '#',
    // },
    { label: 'My Profile', icon: personFill, linkTo: `/profile/${address}` },
    { label: 'Setting', icon: settings2Fill, linkTo: PATH_DASHBOARD('').myAccount.myAccount },
    {
      label: 'Marketplace',
      icon: shopOutlined,
      linkTo: isUserSubscribedStatus ? PATH_DASHBOARD('').shop.list : '/',
    },
  ]
  const [profile, setProfile] = useState<UserProfile>(null)
  //   const classes = useStyles()
  const fetch = async (address) => {
    const profileRes = await getUserProfile(address)
    if (profileRes?.profile) setProfile(profileRes?.profile)
  }
  useEffect(() => {
    fetch(address)
  }, [address])

  return (
    <>
      {/*<Fab variant="extended" color="default" ref={anchorRef} onClick={handleOpen}>*/}
      {/*  <UserAvatar str={address || ''} />*/}
      {/*  {truncateAddress(address)}*/}
      {/*</Fab>*/}
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <UserAvatar str={address || ''} />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: '300px' }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="h6"> {profile?.userName || ''}</Typography>
          <CopyClipboard isAddress value={address} />
          {/*<NavbarPopoverBalance>*/}
          {/*  <PopoverBalanceCol>*/}
          {/*    <NavbarPopoverBalancePara>Total Staking :</NavbarPopoverBalancePara>*/}
          {/*    <NavbarPopoverBalanceValue> 30%</NavbarPopoverBalanceValue>*/}
          {/*  </PopoverBalanceCol>*/}
          {/*  <PopoverBalanceCol>*/}
          {/*    <NavbarPopoverBalancePara>Total SDF:</NavbarPopoverBalancePara>*/}
          {/*    <NavbarPopoverBalanceValue> 5213.00</NavbarPopoverBalanceValue>*/}
          {/*  </PopoverBalanceCol>*/}
          {/*</NavbarPopoverBalance>*/}
        </Box>
        {/* <ListItemButton key={'id'}>
          <ListItemAvatar sx={{ position: 'relative' }}>
            <Avatar src={'/static/mock-images/avatars/dogecoin.svg'} />
          </ListItemAvatar>
          <ListItemText
            primaryTypographyProps={{ typography: 'subtitle2', mb: 0.25 }}
            secondaryTypographyProps={{ typography: 'caption' }}
            primary="0 SDF"
            secondary={'SDF Balance'}
          />
        </ListItemButton> */}
        <ListItemButton key={'id'}>
          <ListItemAvatar sx={{ position: 'relative' }}>
            <Avatar src={'/static/icons/ethereum-eth.svg'} />
          </ListItemAvatar>
          <ListItemText
            primaryTypographyProps={{ typography: 'body2', color: 'grey', mb: 0.25 }}
            secondaryTypographyProps={{ typography: 'h6', fontWeight: 'bold' }}
            primary={`Balance`}
            secondary={`${myBalance.substring(0, 5)}ETH ($${usd || 0})`}
          />
        </ListItemButton>
        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <NextLink key={option.label} href={option.linkTo}>
            <MenuItem onClick={handleClose} sx={{ typography: 'body2', py: 1, px: 2.5 }}>
              <Box
                component={Icon}
                icon={option.icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24,
                }}
              />

              {option.label}
            </MenuItem>
          </NextLink>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={disconnectWallet}
            sx={{ color: '#FF4842', border: '1px solid #FF4842', borderRadius: '18px' }}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  )
}
// const NavbarPopoverBalance = styled('div')({})
// const PopoverBalanceCol = styled('div')({
//   display: 'flex',
//   maxWidth: '200px',
//   // justifyContent: 'space-between',
// })
// const NavbarPopoverBalancePara = styled('p')({
//   fontSize: '14px',
//   color: '#637381',
// })
// const NavbarPopoverBalanceValue = styled('div')({
//   fontSize: '14px',
//   color: '#637381',
//   paddingLeft: '10px',
// })
